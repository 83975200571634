<template>
  <q-select
    v-model="model"
    v-bind="$attrs"
    :options="nationalities"
    :rounded="$q.platform.is.mobile"
    :outlined="$q.platform.is.mobile"
    map-options
    emit-value
    @input="$emit('input', model)"
  />
</template>

<script>
import { nationalities } from 'utils/quasar-countries'

export default {
  inheritAttrs: false,
  props: {
    value: String
  },
  data () {
    return {
      model: this.value,
      nationalities
    }
  }
}
</script>
